@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "fonts/inter.css";


.field_with_errors{
  display: flex;
  flex-grow: 1;
}

input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  width: 100%;
  --c_progress: #A3CD8A;
  --c_thumb: #72916D;
  --c_thumb_border: white;
  --c_track: #E9E9ED;
  --c_track_border: #A2A2AE;
  --c_progress_dark: #8cb177;
  --c_thumb_dark: #5f795b;
  --c_track_dark: #D0D0D7;
  --c_track_border_dark: #676774;
  --l: 5px; /* line thickness*/
  --s: 17px; /* thumb size*/
  
  height: var(--s); /* needed for Firefox*/
  -webkit-appearance :none;
  -moz-appearance :none;
  appearance :none;
  background: none;
  cursor: pointer;
  overflow: hidden;
}

input[type='range']::-moz-range-progress {
  background: var(--c_progress);
  border-radius: 25px;
  padding: 1px;
}
input[type='range']::-moz-range-track {
  background: var(--c_track);
  border: 1px solid var(--c_track_border);
  border-radius: 25px;
}
input[type='range']::-moz-range-thumb {
  background: var(--c_thumb);
  border-color: var(--c_thumb_border);
  border-width: 2px;
  border-radius: 25px;
}
input[type='range']:hover::-moz-range-progress {
  background: var(--c_progress_dark);
}
input[type='range']:hover::-moz-range-track {
  background: var(--c_track_dark);
  border-color: var(--c_track_border_dark);
}
input[type='range']:hover::-moz-range-thumb {
  background: var(--c_thumb_dark);
}

/* chromium */
/* based on https://www.sitepoint.com/css-custom-range-slider/ */
input[type="range" i]::-webkit-slider-thumb{
  height: var(--s);
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--c_thumb);
  box-shadow: 0 0 0 2px inset var(--c_thumb_border);
  border-image: linear-gradient(90deg, var(--c_progress) 50%, var(--c_track) 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw);
  -webkit-appearance: none;
  appearance: none;
}
input[type="range" i]:hover::-webkit-slider-thumb{
  background: var(--c_thumb_dark);
  border-image: linear-gradient(90deg,var(--c_progress_dark) 50%,var(--c_track_dark) 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw);
}

[x-cloak] { display: none; }
