@tailwind components;

@layer components {
    /* checkbox */
    .standard-checkbox {
      @apply w-[1/8] focus:ring-green-500 mt-3 mr-3 h-4 w-4 text-green-600 rounded;
    }
  
    .disappearing-checkbox {
      @apply standard-checkbox disabled:opacity-25;
    }

    /* field label */
    .field-label {
      @apply block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2;
    }

    /* collumn spanning field container */
    .field-div{
      @apply mt-1 sm:mt-0 sm:col-span-1;
    }

    .circle-range {
      display: grid;
      max-width: 360px;
      height: 60px;    
      grid-template-rows: auto auto; /* Two flexible rows */
      grid-auto-flow: dense; /* Automatically fill the grid as new elements are added */
      margin: 5px 5px;
    }

    .circle-range div:has(>label) {
      display: flex;
      width: 32px;
      height: 30px;
    }

    .circle-range div:nth-of-type(odd),
    .circle-range a:nth-of-type(odd) {
      grid-row: 2; /* Top row */
      justify-content: center;
      grid-column: auto / span 2;
    }
    
    .circle-range div:nth-of-type(even),
    .circle-range a:nth-of-type(even) {
      grid-row: 1; /* Bottom row */
      justify-content: center;
      grid-column: auto / span 2;
    }

    .circle-range div:nth-of-type(1),
    .circle-range a:nth-of-type(1) {
      display:none;
    }

    .circle-range div:nth-of-type(odd) {
      border-top: 2px solid green;
    }

    .circle-range::before {
      grid-row: 2;
      content: '';
      display: block;
      height: 100%;
      width: 16px;
      grid-column: 1 / 1;
    }

    .circle-range input[type="radio"] {
      display: none; /* Hide the radio buttons */
    }

    .circle-range + div{
      align-items: center;
      justify-content: flex-end;
    }

    .circle-range > a {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;            
    }

    .circle-range label {
      width: 30px;
      height: 30px;
      font-size: 18px;
      border-radius: 50%;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .circle-range input[type="radio"] {
      display: none; /* Hide the radio buttons */
    }

    .circle-range div label{
      background-color: green;
      color: white;
    }

    .circle-range div:hover ~ div label{
      background-color: white;
      color: black;
    }

    .circle-range:not(:has(div:hover)) div:has(> input:checked) ~ div label{
      background-color: white;
      color: black;
      border: 2px solid green;
    }

}